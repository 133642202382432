'use client';
import { useLocale, useTranslations } from 'next-intl';
import * as Yup from 'yup';

import { PROFILE_APP_ENDPOINT } from '@/src/api/endpoints/app';
import FormConstructor, { FormConstructorData } from '@/src/components/FormConstructor';
import { FormFieldEnum } from '@/src/components/FormConstructor/config';
import PasswordField from '@/src/components/PasswordField';
import { ProfileTabsTypesEnum } from '@/src/components/ProfilePage/components/ProfileTabsList';
import { useRouter } from '@/src/i18n/routing';
import postLogin, { PostLoginParams } from '@/src/services/auth/postLogin';
import { handleAuthData } from '@/src/utils/handleAuthData';
import toErrorWithMessage from '@/src/utils/toErrorWithMessage';

import LoginFormExtraElements from '../LoginFormExtraElements';

import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const locale = useLocale();
  const t = useTranslations();
  const router = useRouter();

  const onSubmit = async (values: PostLoginParams) => {
    const result = await postLogin({ ...values, locale })
      .then(res => {
        const authData = res?.data;
        if (res?.ok && authData) {
          handleAuthData(authData);
          router.push(`${PROFILE_APP_ENDPOINT}/${ProfileTabsTypesEnum.GAMES}`);
        }
        return res;
      })
      .catch(err => {
        toErrorWithMessage(err);
      });
    return result;
  };

  const formData: FormConstructorData = {
    fields: {
      email: {
        placeholder: t('form_fields.email'),
        type: FormFieldEnum.email,
      },
      password: {
        placeholder: t('form_fields.password'),
        type: FormFieldEnum.password,
        component: PasswordField,
      },
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validation.email')).required(t('validation.required')),
      password: Yup.string().required(),
    }),
    onSubmit: {
      action: onSubmit as any,
      text: t('buttons.log_in'),
      className: styles.login_form__button,
    },
    ExtraElement: LoginFormExtraElements,
  };

  return (
    <section className={styles.login_form}>
      <FormConstructor data={formData} />
    </section>
  );
};

export default LoginForm;
