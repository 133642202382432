'use client';
import { useEffect, useState } from 'react';

import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { RESTORE_PASSWORD_APP_ENDPOINT } from '@/src/api/endpoints/app';
import Checkbox from '@/src/components/Checkbox';

import styles from './LoginFormExtraElements.module.scss';

const LoginFormExtraElements = () => {
  const t = useTranslations();
  const [isCheck, setIsCheck] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem('remember-me', JSON.stringify(isCheck));
  }, [isCheck]);

  const toggleCheckbox = () => setIsCheck(!isCheck);

  return (
    <div className={styles.login_extra}>
      <article className={styles.login_extra__remember}>
        <Checkbox callback={toggleCheckbox} bordered />
        <p>{t('form_fields.remember_me')}</p>
      </article>
      <Link href={RESTORE_PASSWORD_APP_ENDPOINT} className={styles.login_extra__forgot_password}>
        {t('form_fields.forgot_password')}
      </Link>
    </div>
  );
};

export default LoginFormExtraElements;
