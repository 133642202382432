import { setCookie } from 'cookies-next';

import { AUTH_TOKEN } from '../constants';
import { AuthDataType } from '../domain/auth/AuthDataType';

export const handleAuthData = (data: AuthDataType) => {
  const { token } = data;
  const rememberMeValue = localStorage.getItem('remember-me');
  const isRememberMe = rememberMeValue ? JSON.parse(rememberMeValue) : false;
  const tokenCookieOptions: { expires?: Date } = {};
  isRememberMe && (tokenCookieOptions.expires = new Date('2050-02-10T13:19:11+0000'));
  token && setCookie(AUTH_TOKEN, token, tokenCookieOptions);
};
