import { AuthDataType } from '@/src/domain/auth/AuthDataType';

import { AuthDataJsonType } from './AuthDataJsonType';

const mapJsonToAuthData = (json: AuthDataJsonType): AuthDataType => ({
  id: json.user.id,
  name: json.user.name,
  email: json.user.email,
  isVerified: json.user.is_active,
  token: json.token,
});

export default mapJsonToAuthData;
