'use client';
import { ChangeEvent, SyntheticEvent, useState } from 'react';

import cn from 'classnames';
import { FieldInputProps, FormikProps } from 'formik';

import { ShowedPassword, UnshowedPassword } from '@/assets/Icons';
import useWindowSize, { widthBreakpoints } from '@/src/hooks/useResize';

import { FormFieldEnum, FormFields } from '../FormConstructor/config';

import styles from './PasswordField.module.scss';

interface CustomFormFieldType {
  form: FormikProps<FormFields>;
  field: FieldInputProps<any>;
  placeholder: string;
}

const PasswordField = (props: CustomFormFieldType & Record<string, string>) => {
  const {
    form: { setFieldValue, touched, setTouched },
    field: { name, value },
    placeholder,
    className,
  } = props;

  const { currentSize } = useWindowSize();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value);
  const toggleShowPassword = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsShowPassword(!isShowPassword);
  };
  const onBlurHandler = () => setTouched({ ...touched, [name]: true });

  return (
    <>
      <input
        className={cn(styles.password_field, className)}
        type={!isShowPassword ? FormFieldEnum.password : FormFieldEnum.text}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlurHandler}
      />
      <div className={styles.password_field__button} role="button" onClick={toggleShowPassword}>
        {isShowPassword ? (
          <ShowedPassword isMobile={currentSize < widthBreakpoints.mobile_xl} />
        ) : (
          <UnshowedPassword isMobile={currentSize < widthBreakpoints.mobile_xl} />
        )}
      </div>
    </>
  );
};

export default PasswordField;
