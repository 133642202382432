import { LOGIN_ENDPOINT } from '@/src/api/endpoints/server';
import { postRequest } from '@/src/api/index';
import { AuthDataJsonType } from '@/src/data/auth/AuthDataJsonType';
import mapJsonToAuthData from '@/src/data/auth/mapJsonToAuthData';
import { AuthDataType } from '@/src/domain/auth/AuthDataType';
import { DataResponse } from '@/src/domain/common/DataResponse';
import { JsonResponse } from '@/src/domain/common/JsonResponse';
import { ServiceDefaultParams } from '@/src/domain/common/ServiceDefaultParams';
import { getXLangHeader } from '@/src/utils/getXLangHeader';
import toErrorWithMessage from '@/src/utils/toErrorWithMessage';

export interface PostLoginParams {
  email: string;
  password: string;
}

const postLogin = async ({
  locale,
  ...params
}: ServiceDefaultParams<PostLoginParams>): Promise<DataResponse<AuthDataType | undefined>> => {
  try {
    const payload = {
      email: params.email,
      password: params.password,
    };
    const response: JsonResponse<AuthDataJsonType> = await postRequest({
      url: LOGIN_ENDPOINT,
      body: payload,
      headers: { ...getXLangHeader(locale) },
    });
    const data = mapJsonToAuthData(response.data);
    return { ok: true, data };
  } catch (e) {
    const message = toErrorWithMessage(e);
    return { ok: false, data: undefined, message };
  }
};

export default postLogin;
